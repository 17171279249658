<template>
  <div>
    <top-menu></top-menu>
    <section id="container">
      <left-menu></left-menu>
      <section id="content">
        <div class="modal" style="z-index:99999;display:block;" v-if="displayModal">
          <div class="modal-content">
            <div class="close">
              <p class="close_btn">
                <img src="/image/sub/study_close_btn.png" alt="닫기" @click="displayModal=false"/>
              </p>
            </div>
            <div style="display:block;">
              <p class="modal_quiz_text" style="font-size:30px;color:#784605;padding:50px 0 50px 0;" v-if="listeningTest.content.answer === listeningTest.content.user_answer && listeningTest.content.user_answer !== null">정답입니다!</p>
              <p class="modal_quiz_text" style="font-size:30px;color:#784605;padding:50px 0 50px 0;" v-else>Wrong Answer</p>
            </div>
          </div>
        </div>
        <section class="quizbox_area">
          <div class="loading loading--box" v-if="loading"></div>
          <div class="studybox_contact_btn"><img src="/image/common/top_ic_contact.png" alt="상담하기" /></div>
          <div class="studybox_h2_box">
            <h2>Study Room</h2>
          </div>
          <div class="studybox_h3_box">
            <div class="studybox_h3_box_in">
              <img src="/image/common/top_title_wood_bg02.png" style="float:left;"/>
              <div class="study-room-book-header">
                <span class="book-code">{{ study_room.content_name }}</span>
                <h3 class="book-title">{{ study_room.content_title }}</h3>
              </div>
              <img src="/image/common/top_title_wood_bg04.png" style="float:left;"/>
            </div>
          </div>
        <div class="quiz_h4_box">
          <h4>Listening & Listening</h4>
        </div>
          <section class="study_myinfo_list_area" style="height:90%;">
            <div class="mypage_scroll_content">
              <section class="study_quiz_box">
                <audio controls v-if="listeningTest.content.file" id="player" style="display: none;">
                  <source :src="listeningTest.content.file" type="audio/mp3">
                  Your browser does not support the audio element.
                </audio>
                <div class="quiz_title_box">
                  <h4>퀴즈</h4>
                </div>
                <div v-if="!complete">
                  <div class="quiz_question_btn clickable" @click="play">
                    <p style="padding-left:50px;">퀴즈 문제듣기</p>
                  </div>
                  <div class="quiz_question_box">
                    <ul>
                      <li>
                        <div class="quiz_question_num">1.</div>
                        <div class="quiz_question_word clickable" :class="{'selected' : (listeningTest.content.user_answer === 'A')}" @click="sendUserAnswer('A')">
                          <p class="quiz_question_word_text">A</p>
                        </div>
                      </li>
                      <li>
                        <div class="quiz_question_num">2.</div>
                        <div class="quiz_question_word clickable" :class="{'selected' : (listeningTest.content.user_answer === 'B')}" @click="sendUserAnswer('B')">
                          <p class="quiz_question_word_text">B</p>
                        </div>
                      </li>
                      <li>
                        <div class="quiz_question_num">3.</div>
                        <div class="quiz_question_word clickable" :class="{'selected' : (listeningTest.content.user_answer === 'C')}" @click="sendUserAnswer('C')">
                          <p class="quiz_question_word_text">C</p>
                        </div>
                      </li>
                      <li>
                        <div class="quiz_question_num">4.</div>
                        <div class="quiz_question_word clickable" :class="{'selected' : (listeningTest.content.user_answer === 'D')}" @click="sendUserAnswer('D')">
                          <p class="quiz_question_word_text">D</p>
                        </div>
                      </li>
                    </ul>
                    <div class="quiz_question_btn_bottom" v-if="listeningTest.content.user_answer">
                      <button type="button" class="btn quizBtn_sheet" >
                        <a class="btn" @click="displayModal=true">정답 확인</a>
                      </button>
                    </div>
                  </div>
                </div>
                <div v-else style="margin-top: -30px;">
                  <img src="/image/common/book-content-completed.png" class="book-content-completed">
                </div>
              </section>
              <div class="quiz_bottom_btn_box">
                <div class="study_result_quiz_num01 clickable" v-if="back" @click="loadContent(back, talkScheduleId, studyRoomId)">{{ back }}</div>
                <img src="/image/sub/quiz_bottom_arr.png" alt="슬래쉬 아이콘" style="vertical-align:middle;margin:0 10px 0 10px;">
                <div class="study_result_quiz_num01 clickable" v-if="next" @click="loadContent(next, talkScheduleId, studyRoomId)">{{ next }}</div>
                <div class="study_result_quiz_num01 clickable" v-else @click="completed" style="width: 105px;border-radius: 10px;font-weight: normal;height: 40px;line-height: 38px;">Completed</div>
              </div>
            </div>
          </section>
        </section>
        <footer-navigation :back="{ name: 'study-room.content', params: { talkScheduleId: talkScheduleId } }"></footer-navigation>
        <content-footer></content-footer>
      </section>
    </section>
    <main-footer></main-footer>
  </div>
</template>

<script>

import LeftMenu from '@/layouts/components/Menu/LeftMenu'
import TopMenu from '@/layouts/components/Menu/TopMenu'
import FooterNavigation from '@/layouts/components/FooterNavigation'
import StudyRoom from '@/models/StudyRoom'
import MainFooter from '@/layouts/components/Footer'
import ContentFooter from '@/layouts/components/ContentFooter'

export default {
  components: {
    LeftMenu,
    TopMenu,
    FooterNavigation,
    MainFooter,
    ContentFooter
  },
  name: 'StudyRoom.Content.Type.ListeningTest',
  data () {
    return {
      study_room: {},
      displayModal: false,
      complete: false,
      loading: false,
      listeningTest: {
        content: {
          file: null,
          user_answer: null,
          answer: null
        }
      },
      pagination: {},
      next: null,
      back: null,
      totalPage: 0,
      talkScheduleId: typeof this.$route.params.talkScheduleId !== 'undefined' ? this.$route.params.talkScheduleId : '',
      studyRoomId: typeof this.$route.params.studyRoomId !== 'undefined' ? this.$route.params.studyRoomId : '',
      page: typeof this.$route.query.page !== 'undefined' ? this.$route.query.page : 1
    }
  },
  mounted () {
    this.loadContent(this.page, this.talkScheduleId, this.studyRoomId)
  },
  methods: {
    async loadContent (page, talkScheduleId, studyRoomId) {
      this.loading = true
      const searchParams = {}
      this.page = page
      searchParams.page = page
      this.$router.replace({ query: searchParams })
      const response = await StudyRoom.viewContent({ page: page, talkScheduleId: talkScheduleId, studyRoomId: studyRoomId })
      this.listeningTest = response.data.data[0]
      $('#player').src = this.listeningTest.content.file // eslint-disable-line
      $('#player').load() // eslint-disable-line
      this.pagination = response.data.meta.pagination
      this.next = this.pagination.next
      this.back = this.pagination.back
      this.study_room = response.data.meta.study_room
      this.loading = false
    },
    play () {
      $('#player')[0].play(); // eslint-disable-line
    },
    sendUserAnswer (data) {
      this.listeningTest.content.user_answer = data
      StudyRoom.updateContent({ page: this.page, talkScheduleId: this.talkScheduleId, studyRoomId: this.studyRoomId, user_answer: this.listeningTest.content.user_answer })
    },
    completed () {
      this.complete = true
      const self = this
      setTimeout(function () {
        console.log(self)
        self.$router.replace({ name: 'study-room.content', params: { talkScheduleId: self.talkScheduleId } })
      }, 2000)
    }
  }
}
</script>

<style scoped>

</style>
